import clsx from "clsx"
import { Link as GatsbyLink } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import type { OutboundLinkProps } from "gatsby-plugin-google-gtag"
import React from "react"

// Derived from https://github.com/ripple/ripplecom-gatsby/blob/master/src/components/UniversalLink.tsx

export interface UniversalLinkAdditionalProps {
  children: string | React.ReactNode
  to?: string | undefined | null
  className?: string
  activeClassName?: string
  partiallyActive?: boolean
  rel?: string
  isInternal?: boolean
  language?: string
}

export type UniversalLinkProps = UniversalLinkAdditionalProps &
  (GatsbyLink<unknown> | OutboundLinkProps)

/**
 * Renders a <GatsbyLink /> or a plain <a> tag depending on what you pass it. If you pass it a local link
 * like /path it will use <GatsbyLink /> component if you pass it a full url like https://google.com it will use a regular a tag,
 * but specifically also use the google analytics link tracker component wrapper A tag
 * @param props
 * @param props.className
 * @param props.children
 * @param props.activeClassName
 * @param props.partiallyActive
 */
export const UniversalLink: React.FC<UniversalLinkProps> = ({
  children,
  className,
  to,
  activeClassName,
  partiallyActive = false,
  isInternal = false,
  ...other
}) => {
  if (!to) {
    return <>{children}</>
  }

  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  const file = /\.[0-9a-z]+$/i.test(to)
  // Check if file
  // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#relative-links

  if (internal || isInternal) {
    if (file) {
      return (
        <a
          href={to}
          {...(other as OutboundLinkProps)}
          className={clsx(className)}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )
    }
    return (
      <GatsbyLink
        to={to.replace(/\/?$/, `/`)}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={clsx(className)}
        {...(other as GatsbyLink<unknown>)}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <OutboundLink
      href={to}
      className={clsx(className)}
      target="_blank"
      {...(other as OutboundLinkProps)}
    >
      {children}
    </OutboundLink>
  )
}
